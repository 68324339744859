/**
 * @generated SignedSource<<f8c7349b61595207bb5cd08743de4aac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedTrackingFunctions_itemSearch$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly item: {
        readonly displayPrice: ReadonlyArray<{
          readonly textType: string | null;
        } | null> | null;
      } | null;
    } | null;
  } | null> | null;
  readonly hasHiddenSoldItems: boolean;
  readonly pageType: string | null;
  readonly trackedAbTests: ReadonlyArray<string | null> | null;
  readonly " $fragmentType": "SbSharedTrackingFunctions_itemSearch";
};
export type SbSharedTrackingFunctions_itemSearch$key = {
  readonly " $data"?: SbSharedTrackingFunctions_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedTrackingFunctions_itemSearch">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "SbSharedTrackingFunctions_itemSearch"
};

(node as any).hash = "87f0d1a7928a65e89fbe5900b0ad6f32";

export default node;
