import { graphql, readInlineData } from 'react-relay';
import { getAbTestV2, trackAbTestV2Variant } from 'dibs-buyer-layout/exports/clientABTestV2';
import {
    BEST_SELLERS_COLLECTION,
    NET_PRICE_15_FILTER_TEST_NAME,
    PERSONALIZED_RERANK_TEST_NAME,
    ITEM_TILE_SHORTER_HEIGHT,
    USER_ENGAGEMENT_BOOST_TEST_NAME,
    HIDE_SOLD_ITEMS,
    ITEM_PRICE_IN_SELLABILITY,
} from '../../utils/abTestHelper';
import { pageTypeConstants } from '../../constants/pageTypeConstants';
import { SortMap } from '../../constants/sbConstants';
import { isUserInEurope } from 'dibs-regional-info/src/regionalInfoHelpers';
import { ITEM_REGION_ON_TIlE } from '../../shared/hooks/useShowItemLocationBadge';
import { trackEligibleForRerankEvent } from '../../utils/tracking/searchBrowse/addPersonalizationTrackingData';
import { getEngagedItems } from 'dibs-buyer-layout/exports/engagedItems';

import { SbSharedTrackingFunctions_viewer$key } from './__generated__/SbSharedTrackingFunctions_viewer.graphql';
import { SbSharedTrackingFunctions_itemSearch$key } from './__generated__/SbSharedTrackingFunctions_itemSearch.graphql';

const viewerFragment = graphql`
    fragment SbSharedTrackingFunctions_viewer on Viewer @inline {
        noSoldItemsOnCollectionPage: featureFlag(feature: "noSoldItemsOnCollectionPage")
    }
`;
const itemSearchFragment = graphql`
    fragment SbSharedTrackingFunctions_itemSearch on ItemSearchQueryConnection @inline {
        pageType
        hasHiddenSoldItems
        trackedAbTests
        edges {
            node {
                item {
                    displayPrice(
                        page: searchAndBrowse
                        isTrade: $isTrade
                        priceBookName: $priceBookName
                    ) {
                        textType
                    }
                }
            }
        }
    }
`;

type PersonalizedRerankProps = {
    pageType?: string | null;
    sortValue?: string | null;
    isEligibleForRerank?: boolean | null;
};
export const personalizedRerankTracking = ({
    pageType,
    sortValue,
    isEligibleForRerank,
}: PersonalizedRerankProps): void => {
    // This if should be the same as
    // https://github.com/1stdibs/dibs-graphql/blob/release/src/schema/inventory/itemSearch/solrQueries/generateResultQuery.ts#L228
    if (
        sortValue === SortMap.relevance ||
        sortValue === SortMap.buyPageRelevance ||
        (pageType !== pageTypeConstants.BUY && sortValue === SortMap.recommended)
    ) {
        trackAbTestV2Variant(PERSONALIZED_RERANK_TEST_NAME);
    }

    if (isEligibleForRerank) {
        trackEligibleForRerankEvent();
    }
};

export const bestSellersSellabilityTracking = ({
    displayUriRef,
    pageType,
}: {
    displayUriRef: string;
    pageType: string;
}): void => {
    if (pageType === pageTypeConstants.COLLECTION && displayUriRef.includes('/best-sellers/')) {
        trackAbTestV2Variant(BEST_SELLERS_COLLECTION);
    }
};

export const itemRegionOnTileTracking = (): void => {
    const isInEurope = isUserInEurope();
    if (isInEurope) {
        trackAbTestV2Variant(ITEM_REGION_ON_TIlE);
    }
};

export const newNetPriceFilterTracking = ({ isTrade }: { isTrade?: boolean | null }): void => {
    if (isTrade) {
        trackAbTestV2Variant(NET_PRICE_15_FILTER_TEST_NAME);
    }
};
export const itemTileShorterHeightTracking = (): void => {
    trackAbTestV2Variant(ITEM_TILE_SHORTER_HEIGHT);
};

export const userEngagementBoostTracking = (resultItemIds: string[]): void => {
    const engagedItems = getEngagedItems();
    if (!engagedItems.length || !resultItemIds.length) {
        return;
    }
    const engagedItemsMap = engagedItems.reduce(
        (acc, itemId) => ({ ...acc, [itemId]: true }),
        <Record<string, boolean>>{}
    );
    const hasEngagedItemsInResult = resultItemIds.some(
        resultItemId => engagedItemsMap[resultItemId]
    );
    if (hasEngagedItemsInResult) {
        trackAbTestV2Variant(USER_ENGAGEMENT_BOOST_TEST_NAME);
    }
};

export const hideSoldItemsTracking = (
    viewerRef: SbSharedTrackingFunctions_viewer$key,
    itemSearchRef: SbSharedTrackingFunctions_itemSearch$key,
    pageNumber: number
): void => {
    const viewer = readInlineData(viewerFragment, viewerRef);
    const itemSearch = readInlineData(itemSearchFragment, itemSearchRef);
    const hideSoldItemsVariant = getAbTestV2(HIDE_SOLD_ITEMS);
    const hasSoldItems = (itemSearch?.edges || []).some(
        edge => edge?.node?.item?.displayPrice?.[0]?.textType === 'SOLD'
    );
    const trackAbTestControl =
        itemSearch?.pageType !== pageTypeConstants.COLLECTION ||
        viewer?.noSoldItemsOnCollectionPage;
    if (
        pageNumber === 1 &&
        ((hideSoldItemsVariant?.variant === 'variant' && itemSearch?.hasHiddenSoldItems) ||
            (hideSoldItemsVariant?.variant === 'control' && hasSoldItems && trackAbTestControl))
    ) {
        trackAbTestV2Variant(HIDE_SOLD_ITEMS);
    }
};

export const localizedEUBoostTracking = (
    itemSearchRef: SbSharedTrackingFunctions_itemSearch$key
): void => {
    const EURO_ITEM_BOOST = 'EuroItemBoost';
    const itemSearch = readInlineData(itemSearchFragment, itemSearchRef);

    if (itemSearch.trackedAbTests?.includes(EURO_ITEM_BOOST)) {
        trackAbTestV2Variant(EURO_ITEM_BOOST);
    }
};

export const itemPriceInSellabilityTracking = ({
    pageType,
    sortValue,
}: {
    pageType?: string | null;
    sortValue?: string | null;
}): void => {
    if (
        (pageType === pageTypeConstants.SEARCH && sortValue === SortMap.relevance) ||
        sortValue === SortMap.recommended
    ) {
        trackAbTestV2Variant(ITEM_PRICE_IN_SELLABILITY);
    }
};
